import {
  ICustomerBillingInformation,
  ICustomerProfile,
  IPaymentAuthorizationInfo,
  IShippingInformation,
} from './InvoicePayment';

export enum CustomerType {
  INDIVIDUAL = 'INDIVIDUAL',
  BUSINESS = 'BUSINESS',
}

export const CCChargeFeePercent = 0.03;

export const emptyPaymentAuthorization: IPaymentAuthorizationInfo = {
  cardNumber: '',
  userName: '',
  expirationDate: '',
  cvv: '',
  focus: '',
  amount: '',
};
export const emptyCustomerProfile: ICustomerProfile = {
  customerId: '',
  invoiceNumber: '',
};

export const emptyCustomerBilling: ICustomerBillingInformation = {
  firstName: '',
  lastName: '',
  company: '',
  customerType: CustomerType.BUSINESS,
  address: '',
  city: '',
  state: '',
  zipCode: '',
  country: '',
  phone: '',
  fax: '',
};
export const emptyShipping: IShippingInformation = {
  shippingFirstName: '',
  shippingLastName: '',
  shippingCompany: '',
  shippingAddress: '',
  shippingCity: '',
  shippingState: '',
  shippingZipCode: '',
  shippingCountry: '',
  shippingPhone: '',
  shippingFax: '',
};

export const customerTypesOptions: { value: string; label: string }[] = [
  { value: 'INDIVIDUAL', label: 'Individual' },
  { value: 'BUSINESS', label: 'Business' },
];

/** Customer Profile required fields. */
export const customerProfileRequired = ['customerId', 'invoiceNumber'] as const;

/** Payment required fields. */
export const paymentRequired = [
  'cardNumber',
  'expirationDate',
  'cvv',
  'amount',
] as const;

/** Billing required fields. */
export const billingRequired = [
  'firstName',
  'lastName',
  'customerType',
  'address',
  'city',
  'state',
  'zipCode',
  'country',
] as const;

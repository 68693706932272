import { IAuthorizeCC } from './AuthorizeCreditCard';
import { axiosPost } from 'helpers/axios-helpers';

export const createCustomerProfile = async (
  body: IAuthorizeCC,
  access_token?: string,
) => {
  const response = await axiosPost<
    object,
    {
      statusCode: number;
      error: string;
      customerProfileRecordId?: number;
    }
  >({
    route: `customer-profile/create`,
    data: {
      creditCardInfo: {
        cardNumber: body.cardNumber,
        expirationDate: body.expirationDate,
        cvc: body.cvv,
      },
      customerBillingInformation: {
        firstName: body.firstName,
        lastName: body.lastName,
        company: body.company,
        address: body.address,
        city: body.city,
        state: body.state,
        zipCode: body.zipCode,
        country: body.country,
        phone: body.phone,
        fax: body.fax,
      },
      shippingInformation: {
        shippingFirstName: body.shippingFirstName,
        shippingLastName: body.shippingLastName,
        shippingCompany: body.shippingCompany,
        shippingAddress: body.shippingAddress,
        shippingCity: body.shippingCity,
        shippingState: body.shippingState,
        shippingZipCode: body.shippingZipCode,
        shippingCountry: body.shippingCountry,
        shippingPhone: body.shippingPhone,
        shippingFax: body.shippingFax,
      },
      ICustomerInformation: {
        customerId: body.customerId,
        description: '',
        email: body.email,
        customerType: body.customerType.toLowerCase(),
      },
      customerProfileRecordId: body.customerProfileRecordId,
    },
    accessToken: access_token || '',
  });
  return response.data;
};

import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { Card, CardHeader, Collapse, Grid, IconButton } from '@mui/material';
import classnames from 'classnames';
import { HTMLAttributes } from 'react';

interface ICollapsibleCard
  extends Partial<Omit<HTMLAttributes<HTMLDivElement>, 'children'>> {
  title: string;
  children: JSX.Element;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}
const CollapsibleCard = (props: ICollapsibleCard) => {
  const { title, children, isOpen, setIsOpen, ...containerProps } = props;

  return (
    <div className="paper-container" {...containerProps}>
      <Card className="card-container">
        <CardHeader
          style={{ padding: isOpen ? '0px 0px 24px 0px' : '0px' }}
          title={title}
          titleTypographyProps={{
            align: 'left',
            fontSize: '24px',
            fontWeight: 600,
            color: '#202945',
          }}
          action={
            <Grid container justifyContent="flex-end">
              <IconButton
                style={{
                  backgroundColor: '#ebebeb',
                  borderRadius: '50%',
                  width: 25,
                  height: 25,
                  color: '#404547',
                }}
                onClick={() => setIsOpen(!isOpen)}
              >
                <KeyboardArrowDownRoundedIcon
                  className={classnames('card-button', {
                    'card-button_rotated': !isOpen,
                  })}
                />
              </IconButton>
            </Grid>
          }
        />
        <Collapse in={isOpen}>{children}</Collapse>
      </Card>
    </div>
  );
};

export default CollapsibleCard;

import { axiosGet, axiosPost } from 'helpers/axios-helpers';
import { CustomerType, IFormValues } from './InvoicePayment';
import { currencyStringToNumber } from 'helpers/method-helpers';
import { InvoiceTransaction } from './types';

interface Response {
  statusCode: number;
  status: boolean;
  error: string | null;
}

interface IAddress {
  firstName: string;
  lastName: string;
  company?: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;

  phone?: string;
  fax?: string;
}

interface IPayInvoice {
  customerInformation: {
    customerId: string;
    invoiceNumber: string;
    customerType?: CustomerType;
  };
  creditCard: {
    cardNumber: string;
    expirationDate: string;
    amount: number;
    cvc: string;
    isExempted: boolean;
  };

  billingAddress: IAddress;
  shippingAddress?: Partial<IAddress>;
}

export const payInvoice = async (
  formValues: IFormValues,
  accessToken: string,
  isExempted: boolean,
) => {
  const response = await axiosPost<IPayInvoice, Response>({
    route: 'invoice-payment/pay',
    accessToken,
    data: {
      customerInformation: {
        customerId: formValues.customerId,
        customerType: formValues.customerType || CustomerType.INDIVIDUAL,
        invoiceNumber: formValues.invoiceNumber,
      },
      creditCard: {
        cardNumber: formValues.cardNumber,
        expirationDate: formValues.expirationDate,
        amount: currencyStringToNumber(formValues.amount),
        cvc: formValues.cvv,
        isExempted: isExempted,
      },
      billingAddress: {
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        company: formValues.company || undefined,
        country: formValues.country,
        state: formValues.state,
        city: formValues.city,
        address: formValues.address,
        zipCode: formValues.zipCode,
        phone: formValues.phone || undefined,
        fax: formValues.fax || undefined,
      },

      shippingAddress: {
        firstName: formValues.shippingFirstName || undefined,
        lastName: formValues.shippingLastName || undefined,
        company: formValues.shippingCompany || undefined,
        country: formValues.shippingCountry || undefined,
        state: formValues.shippingState || undefined,
        city: formValues.shippingCity || undefined,
        address: formValues.shippingAddress || undefined,
        zipCode: formValues.shippingZipCode || undefined,
        phone: formValues.shippingPhone || undefined,
        fax: formValues.shippingFax || undefined,
      },
    },
  });

  return response?.data;
};

interface InvoiceTransactions {
  transactions: InvoiceTransaction[];
  count: number;
}

export const getInvoiceTransactions = async (
  access_token: string | undefined,
  page: number,
  perPage: number,
  sort?: string | null,
  order?: string,
  filter?: string,
) => {
  const response = await axiosGet<InvoiceTransactions>({
    route: `invoice-payment/transactions?page=${
      page
    }&per_page=${perPage}${sort ? `&sort=${sort}&order=${order}` : ''}${
      filter ? `&filter=${filter}` : ''
    }`,
    accessToken: access_token,
  });
  return response.data;
};

/**
 *
 * @param currency string number with thousand separators
 * @returns casted amount to number
 * @example '9,999,999.99' => 9999999.99
 */
export const currencyStringToNumber = (currency: string) => {
  const castCurrency = +currency.replaceAll(',', '');

  if (isNaN(castCurrency)) return 0;

  return castCurrency;
};

import 'react-credit-cards-2/dist/lib/styles.scss';

import { yupResolver } from '@hookform/resolvers/yup';
import { Link, Typography } from '@mui/material';
import Button from '@mui/material/Button/Button';
import InputAdornment from '@mui/material/InputAdornment/InputAdornment';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { Auth } from 'aws-amplify';
import { CurrencyNumericFormat } from 'components/CurrencyNumericFormat/CurrencyNumericFormat';
import { StatusCodes } from 'http-status-codes';
import React, { FC, useContext, useMemo, useState } from 'react';
import Cards, { Focused } from 'react-credit-cards-2';
import { FieldErrors, useForm } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';
import countryList from 'react-select-country-list';
import { AccessTokenContext } from '../../App';
import { BasicModal } from '../../components/BasicModal/BasicModal';
import CollapsibleCard from '../../components/CollapsableCard/CollapsableCard';
import Header from '../../components/Header/Header';
import { Spinner } from '../../components/Spinner/Spinner';
import routes from '../../routes/routes';
import {
  billingRequired,
  customerProfileRequired,
  customerTypesOptions,
  emptyCustomerBilling,
  emptyCustomerProfile,
  emptyPaymentAuthorization,
  emptyShipping,
  paymentRequired,
} from './constValues';
import { calculateFeeAndTotal } from './invoicePayment.helpers';
import InvoicePaymentSection from './InvoicePaymentSection';
import { payInvoice } from './invoice.axios';
import PaymentConfirmationModal from './paymentConfirmationModal';
import { validationSchema } from './validationSchema';
import { showErrorToast } from 'helpers/showErrorToast';

export interface IPaymentAuthorizationInfo {
  cardNumber: string;
  userName: string;
  expirationDate: string;
  cvv: string;
  focus: Focused;
  amount: string;
}

export interface inputsText {
  label: string;
  id: string;
  type: string;
  helperText?: string;
  error?: any;
}
export interface ICustomerProfile {
  customerId: string;
  invoiceNumber: string;
}

export enum CustomerType {
  INDIVIDUAL = 'INDIVIDUAL',
  BUSINESS = 'BUSINESS',
}

export interface ICustomerBillingInformation {
  firstName: string;
  lastName: string;
  company: string;
  address: string;
  customerType: CustomerType | '';
  city: string;
  state: string;
  zipCode: string;
  country: string;
  phone: string;
  fax: string;
}

export interface IShippingInformation {
  shippingFirstName: string;
  shippingLastName: string;
  shippingCompany: string;
  shippingAddress: string;
  shippingCity: string;
  shippingState: string;
  shippingZipCode: string;
  shippingCountry: string;
  shippingPhone: string;
  shippingFax: string;
}

export interface IFormValues
  extends ICustomerProfile,
    IPaymentAuthorizationInfo,
    ICustomerBillingInformation,
    IShippingInformation {}

interface IFormValuesRequired
  extends ICustomerProfile,
    Omit<IPaymentAuthorizationInfo, 'useName'>,
    Omit<ICustomerBillingInformation, 'company' | 'phone' | 'fax'> {}

type IFormAccordions = Record<
  'customerProfile' | 'payment' | 'billing' | 'shipping',
  boolean
>;

const InvoicePayment: FC = () => {
  const {
    handleSubmit,
    register,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      ...emptyPaymentAuthorization,
      ...emptyCustomerProfile,
      ...emptyCustomerBilling,
      ...emptyShipping,
    },
  });

  const { accessToken } = useContext(AccessTokenContext);
  const navigate = useNavigate();
  const location = useLocation();
  const isExempted = location.pathname === routes.exemptedPayment;

  const onSubmit = async () => {
    if (!accessToken) return;
    setOpenBasicModal(true);

    try {
      const formValues = getValues();

      const invoicePaymentResponse = await payInvoice(
        formValues,
        accessToken,
        isExempted,
      );
      if (invoicePaymentResponse.statusCode === StatusCodes.OK) {
        navigate(routes.successfulCCCharge);
      } else {
        showErrorToast(invoicePaymentResponse.error);
      }
    } catch (error: any) {
      if (error.response) {
        if (error.response.status === StatusCodes.FORBIDDEN) {
          showErrorToast('Your session has expired, please log in again.');
          Auth.signOut();
          return;
        }
        if (error?.response?.data?.errors) {
          error.response.data.errors.map((error: string) =>
            showErrorToast(error),
          );
          return;
        }
      } else {
        showErrorToast(
          'The system is currently unavailable. Please try again later.',
        );
      }
    } finally {
      setOpenBasicModal(false);
    }
  };

  const [openBasicModal, setOpenBasicModal] = useState<boolean>(false);
  const [checkSameInfo, setCheckSameInfo] = useState<boolean>(false);
  const [copyDropdown, setCopyDropdown] = useState<string>('');
  const [resetDropdown, setResetDropdown] = useState<boolean>(false);
  const [customerProfile, setCustomerProfile] = useState<ICustomerProfile>({
    ...emptyCustomerProfile,
  });
  const [paymentAuthorizationInfo, setPaymentAuthorizationInfo] =
    useState<IPaymentAuthorizationInfo>({ ...emptyPaymentAuthorization });
  const [customerBillingInformation, setCustomerBillingInformation] =
    useState<ICustomerBillingInformation>({ ...emptyCustomerBilling });
  const [shippingInformation, setShippingInformation] =
    useState<IShippingInformation>({ ...emptyShipping });
  const [isPaymentConfirmationOpen, setIsPaymentConfirmation] = useState(false);
  const [formAccordions, setFormAccordions] = useState<IFormAccordions>({
    billing: true,
    customerProfile: true,
    payment: true,
    shipping: true,
  });

  const options = useMemo(() => {
    const countries = countryList().getData();
    const unitedStates = countries.find(
      (item: { label: string }) => item.label === 'United States',
    );
    const filteredCountries = countries.filter(
      (item: { label: string }) => item.label !== 'United States',
    );
    const updatedOptions = [
      {
        value: unitedStates?.value,
        label: unitedStates?.label,
      },
      ...filteredCountries.map((item) => ({
        value: item.value,
        label: item.label,
      })),
    ];
    return updatedOptions;
  }, []);

  const customerProfileInputs: inputsText[] = [
    {
      label: 'Customer ID*',
      id: 'customerId',
      type: 'text',
      error: Boolean(errors.customerId),
      helperText: errors.customerId?.message,
    },
    {
      label: 'Invoice Number*',
      id: 'invoiceNumber',
      type: 'text',
      error: Boolean(errors.invoiceNumber),
      helperText: errors.invoiceNumber?.message,
    },
    {
      label: 'Phone*',
      id: 'phone',
      type: 'numeric',
      error: Boolean(errors.phone),
      helperText: errors.phone?.message,
    },
  ];

  const customerBillingInformationInputs: inputsText[] = [
    {
      label: 'First Name*',
      id: 'firstName',
      type: 'text',
      error: Boolean(errors.firstName),
      helperText: errors.firstName?.message,
    },
    {
      label: 'Last Name*',
      id: 'lastName',
      type: 'text',
      error: Boolean(errors.lastName),
      helperText: errors.lastName?.message,
    },
    {
      label: 'Customer Type*',
      id: 'customerType',
      type: 'dropdown',
      error: Boolean(errors.customerType),
      helperText: errors.customerType?.message,
    },
    {
      label: 'Company',
      id: 'company',
      type: 'text',
      error: Boolean(errors.company),
      helperText: errors.company?.message,
    },
    {
      label: 'Address*',
      id: 'address',
      type: 'text',
      error: Boolean(errors.address),
      helperText: errors.address?.message,
    },
    {
      label: 'City*',
      id: 'city',
      type: 'text',
      error: Boolean(errors.city),
      helperText: errors.city?.message,
    },
    {
      label: 'State/Province*',
      id: 'state',
      type: 'text',
      error: Boolean(errors.state),
      helperText: errors.state?.message,
    },
    {
      label: 'Zip Code*',
      id: 'zipCode',
      type: 'text',
      error: Boolean(errors.zipCode),
      helperText: errors.zipCode?.message,
    },
    {
      label: 'Country*',
      id: 'country',
      type: 'dropdown',
      error: Boolean(errors.country),
      helperText: errors.country?.message,
    },
    {
      label: 'Fax',
      id: 'fax',
      type: 'numeric',
    },
  ];

  const shippingInformationInputs: inputsText[] = [
    { label: 'First Name', id: 'shippingFirstName', type: 'text' },
    { label: 'Last Name', id: 'shippingLastName', type: 'text' },
    { label: 'Company', id: 'shippingCompany', type: 'text' },
    { label: 'Address', id: 'shippingAddress', type: 'text' },
    { label: 'City', id: 'shippingCity', type: 'text' },
    { label: 'State/Province', id: 'shippingState', type: 'text' },
    { label: 'Zip Code', id: 'shippingZipCode', type: 'text' },
    { label: 'Country', id: 'shippingCountry', type: 'dropdown' },
    { label: 'Phone', id: 'shippingPhone', type: 'numeric' },
    { label: 'Fax', id: 'shippingFax', type: 'numeric' },
  ];

  React.useEffect(() => {
    handleCheckSameInformation();
  }, [checkSameInfo]);

  React.useEffect(() => {
    return () => {
      setResetDropdown(false);
    };
  }, [resetDropdown]);

  function handleInputPaymentAuthorization<
    K extends keyof IPaymentAuthorizationInfo,
    V extends IPaymentAuthorizationInfo[K],
  >(key: K, value: V) {
    setPaymentAuthorizationInfo({
      ...paymentAuthorizationInfo,
      [key]: value,
    });
  }

  const handleInputFocus: TextFieldProps['onFocus'] = (evt) => {
    setPaymentAuthorizationInfo((prev) => ({
      ...prev,
      focus: evt.target.name as Focused,
    }));
  };

  const handleInputCustomerBillingInfo = (key: string, value: string): void => {
    setCustomerBillingInformation({
      ...customerBillingInformation,
      [key]: value,
    });
  };
  const handleInputCustomerProfile = (key: string, value: string): void => {
    setCustomerProfile({
      ...customerProfile,
      [key]: value,
    });
  };

  const handleInputShippingInfo = (key: string, value: string): void => {
    setShippingInformation({
      ...shippingInformation,
      [key]: value,
    });
  };

  const handlePaymentConfirmationConfirm = async () => {
    setIsPaymentConfirmation(false);

    await onSubmit();
  };

  const formReset = () => {
    setPaymentAuthorizationInfo({ ...emptyPaymentAuthorization });
    setCustomerBillingInformation({ ...emptyCustomerBilling });
    setShippingInformation({ ...emptyShipping });
    setCustomerProfile({ ...emptyCustomerProfile });
    reset();
    setResetDropdown(true);
    setCheckSameInfo(false);
    setCopyDropdown('');
  };

  const handleCheckSameInformation = () => {
    setValue('shippingFirstName', checkSameInfo ? getValues('firstName') : '');
    setValue('shippingLastName', checkSameInfo ? getValues('lastName') : '');
    setValue('shippingCompany', checkSameInfo ? getValues('company') : '');
    setValue('shippingAddress', checkSameInfo ? getValues('address') : '');
    setValue('shippingCity', checkSameInfo ? getValues('city') : '');
    setValue('shippingState', checkSameInfo ? getValues('state') : '');
    setValue('shippingZipCode', checkSameInfo ? getValues('zipCode') : '');
    setValue('shippingCountry', checkSameInfo ? getValues('country') : '');
    setValue('shippingPhone', checkSameInfo ? getValues('phone') : '');
    setValue('shippingFax', checkSameInfo ? getValues('fax') : '');
    if (checkSameInfo) {
      setShippingInformation({
        shippingFirstName: customerBillingInformation.firstName,
        shippingLastName: customerBillingInformation.lastName,
        shippingCompany: customerBillingInformation.company,
        shippingAddress: customerBillingInformation.address,
        shippingCity: customerBillingInformation.city,
        shippingState: customerBillingInformation.state,
        shippingZipCode: customerBillingInformation.zipCode,
        shippingCountry: customerBillingInformation.country,
        shippingPhone: customerBillingInformation.phone,
        shippingFax: customerBillingInformation.fax,
      });
    } else {
      setShippingInformation({
        ...emptyShipping,
      });
    }
  };

  /** onError we expand those CollapsibleCards within fieldErrors. */
  const onError = (errors: FieldErrors<IFormValuesRequired>) => {
    const newFormAccordions = { ...formAccordions };

    const errorsKey = Object.keys(errors) as (keyof IFormValuesRequired)[];
    if (customerProfileRequired.some((key) => errorsKey.includes(key))) {
      newFormAccordions.customerProfile = true;
    }
    if (paymentRequired.some((key) => errorsKey.includes(key))) {
      newFormAccordions.payment = true;
    }
    if (billingRequired.some((key) => errorsKey.includes(key))) {
      newFormAccordions.billing = true;
    }

    setFormAccordions(newFormAccordions);
  };

  const { chargeFee, totalCharge } = calculateFeeAndTotal(getValues().amount);

  return (
    <>
      <Header />
      <form
        onSubmit={handleSubmit(() => setIsPaymentConfirmation(true), onError)}
        noValidate
      >
        <div className="creditCard-main-container">
          <BasicModal open={openBasicModal}>
            <div className="modal-div">
              <Spinner />
              <p className="spinner-title">Loading</p>
              <span className="spinner-description"> Please hold</span>
            </div>
          </BasicModal>
          <CollapsibleCard
            title="Customer Profile Information"
            isOpen={formAccordions.customerProfile}
            setIsOpen={(isOpen) =>
              setFormAccordions((prevValues) => ({
                ...prevValues,
                customerProfile: isOpen,
              }))
            }
          >
            <InvoicePaymentSection
              options={options}
              register={register}
              inputs={customerProfileInputs}
              resetDropdown={resetDropdown}
              handleInput={handleInputCustomerProfile}
              state={customerProfile}
            />
          </CollapsibleCard>
          <CollapsibleCard
            title="Payment Information"
            isOpen={formAccordions.payment}
            setIsOpen={(isOpen) =>
              setFormAccordions((prevValues) => ({
                ...prevValues,
                payment: isOpen,
              }))
            }
          >
            <div className="creditCard-container">
              <Cards
                number={paymentAuthorizationInfo.cardNumber}
                expiry={paymentAuthorizationInfo.expirationDate}
                cvc={paymentAuthorizationInfo.cvv}
                name={paymentAuthorizationInfo.userName}
                focused={paymentAuthorizationInfo.focus}
              />
              <div>
                <TextField
                  {...register('cardNumber')}
                  label="Card Number*"
                  placeholder="0000 0000 0000 0000"
                  className="credit-card-capture-input"
                  variant="outlined"
                  name="cardNumber"
                  value={paymentAuthorizationInfo.cardNumber}
                  onInput={(e) => {
                    handleInputPaymentAuthorization(
                      'cardNumber',
                      (e.target as HTMLInputElement).value,
                    );
                  }}
                  onFocus={handleInputFocus}
                  InputLabelProps={{
                    shrink: true,
                    style: {
                      fontFamily: 'proxima-nova, sans-serif !important',
                    },
                  }}
                  InputProps={{
                    classes: {
                      input: 'placeholder-style',
                    },
                    style: {
                      fontFamily: `proxima-nova, sans-serif !important`,
                    },
                  }}
                  type="tel"
                  onKeyDown={(event) => {
                    const key = event.key;
                    const isNumericKey = /^[0-9]$/.test(key);
                    const isDeleteBackspaceTabKey =
                      /^(Delete|Backspace|Tab)$/.test(key);
                    if (!isNumericKey && !isDeleteBackspaceTabKey) {
                      event.preventDefault();
                    }
                  }}
                  error={Boolean(errors.cardNumber)}
                  helperText={errors.cardNumber?.message}
                />

                <TextField
                  {...register('userName')}
                  label="Name"
                  placeholder="Name"
                  className="credit-card-capture-input"
                  variant="outlined"
                  name="userName"
                  value={paymentAuthorizationInfo.userName}
                  onChange={(e) => {
                    handleInputPaymentAuthorization('userName', e.target.value);
                  }}
                  onFocus={handleInputFocus}
                  InputLabelProps={{
                    shrink: true,
                    style: {
                      fontFamily: 'proxima-nova, sans-serif !important',
                    },
                  }}
                  InputProps={{
                    classes: {
                      input: 'placeholder-style',
                    },
                    style: {
                      fontFamily: 'proxima-nova, sans-serif !important',
                    },
                  }}
                />
                <div style={{ display: 'flex' }}>
                  <TextField
                    {...register('expirationDate')}
                    label="Expiration Date*"
                    placeholder="MM / YY"
                    className="credit-card-capture-expiration"
                    variant="outlined"
                    name="expirationDate"
                    value={paymentAuthorizationInfo.expirationDate}
                    onFocus={handleInputFocus}
                    style={{ paddingRight: '48px !important' }}
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        fontFamily: 'proxima-nova, sans-serif !important',
                      },
                    }}
                    InputProps={{
                      classes: {
                        input: 'placeholder-style',
                      },
                      style: {
                        fontFamily: 'proxima-nova, sans-serif !important',
                      },
                      inputProps: {
                        maxLength: 5,
                      },
                    }}
                    onInput={(e) => {
                      const inputValue = (e.target as HTMLInputElement).value;
                      const sanitizedValue = inputValue.replace(/[^\d]/g, '');
                      let formattedValue = '';
                      if (sanitizedValue.length > 0) {
                        formattedValue = sanitizedValue.slice(0, 2);
                        if (sanitizedValue.length > 2) {
                          formattedValue += '/' + sanitizedValue.slice(2);
                        }
                      }
                      handleInputPaymentAuthorization(
                        'expirationDate',
                        formattedValue,
                      );
                    }}
                    error={Boolean(errors.expirationDate)}
                    helperText={errors.expirationDate?.message}
                  />

                  <TextField
                    {...register('cvv')}
                    label="CVV*"
                    placeholder="000"
                    className="credit-card-capture-input"
                    value={paymentAuthorizationInfo.cvv}
                    variant="outlined"
                    onFocus={handleInputFocus}
                    name="cvv"
                    onInput={(e) => {
                      const inputValue = (e.target as HTMLInputElement).value;
                      const fourDigits = inputValue
                        .replace(/\D/g, '')
                        .slice(0, 4);
                      handleInputPaymentAuthorization('cvv', fourDigits);
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        fontFamily: 'proxima-nova, sans-serif !important',
                      },
                    }}
                    InputProps={{
                      classes: {
                        input: 'placeholder-style',
                      },
                      style: {
                        fontFamily: 'proxima-nova, sans-serif !important',
                      },
                    }}
                    error={Boolean(errors.cvv)}
                    helperText={errors.cvv?.message}
                  />
                </div>

                <TextField
                  {...register('amount')}
                  label="Amount*"
                  placeholder="0.00"
                  className="credit-card-capture-input"
                  value={paymentAuthorizationInfo.amount}
                  variant="outlined"
                  onFocus={handleInputFocus}
                  name="amount"
                  onInput={(e) => {
                    handleInputPaymentAuthorization(
                      'amount',
                      (e.target as HTMLInputElement).value,
                    );
                  }}
                  InputLabelProps={{
                    shrink: true,
                    sx: { fontFamily: 'proxima-nova, sans-serif' },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    inputComponent: CurrencyNumericFormat as any, // They use any in the documentation.
                    sx: { fontFamily: 'proxima-nova, sans-serif' },
                    classes: {
                      input: 'placeholder-style',
                    },
                  }}
                  error={Boolean(errors.amount)}
                  helperText={errors.amount?.message}
                />
                {!isExempted && (
                  <>
                    <Typography
                      sx={{
                        marginY: 2,
                        fontFamily: 'proxima-nova, sans-serif',
                      }}
                    >
                      There will be a 3% convenience fee added to your total
                      because you have opted to pay by credit card.
                    </Typography>
                    <TextField
                      disabled
                      label="Convenience Fee"
                      className="credit-card-capture-input"
                      value={chargeFee ?? '0.00'}
                      variant="standard"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                        inputComponent: CurrencyNumericFormat as any, // They use any in the documentation.
                        sx: { fontFamily: 'proxima-nova, sans-serif' },
                        classes: {
                          input: 'placeholder-style',
                        },
                      }}
                    />
                    <TextField
                      disabled
                      label="Total Amount"
                      className="credit-card-capture-input"
                      value={totalCharge ?? '0.00'}
                      variant="standard"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                        inputComponent: CurrencyNumericFormat as any, // They use any in the documentation.
                        sx: { fontFamily: 'proxima-nova, sans-serif' },
                        classes: {
                          input: 'placeholder-style',
                        },
                      }}
                    />
                  </>
                )}
              </div>
            </div>
          </CollapsibleCard>

          <CollapsibleCard
            title="Customer Billing Information"
            isOpen={formAccordions.billing}
            setIsOpen={(isOpen) =>
              setFormAccordions((prevValues) => ({
                ...prevValues,
                billing: isOpen,
              }))
            }
          >
            <InvoicePaymentSection
              options={options}
              customerTypeOptions={customerTypesOptions}
              register={register}
              inputs={customerBillingInformationInputs}
              resetDropdown={resetDropdown}
              handleInput={handleInputCustomerBillingInfo}
              state={customerBillingInformation}
            />
          </CollapsibleCard>

          <CollapsibleCard
            /** By now we're hiding the Shipping information since it's not needed, and makes the form leaner. */
            style={{ display: 'none' }}
            title="Shipping Information"
            isOpen={formAccordions.shipping}
            setIsOpen={(isOpen) =>
              setFormAccordions((prevValues) => ({
                ...prevValues,
                shipping: isOpen,
              }))
            }
          >
            <InvoicePaymentSection
              options={options}
              dropdownValue={copyDropdown}
              register={register}
              inputs={shippingInformationInputs}
              handleInput={handleInputShippingInfo}
              state={shippingInformation}
              hasCheckBox
              resetDropdown={resetDropdown}
              checkSameInfo={checkSameInfo}
              setCheckSameInfo={setCheckSameInfo}
            />
          </CollapsibleCard>
          <div className="credit-card-capture-button-container">
            <Button
              className="btn cancel-action-btn"
              style={{ marginRight: 32, backgroundColor: 'white' }}
              onClick={formReset}
            >
              Reset
            </Button>
            <Button
              type="submit"
              className="btn submit-action-btn"
              variant="contained"
            >
              Submit
            </Button>
          </div>
          <div className="credit-card-capture-helper-text">
            <Typography>
              In case of issues charging a card, please contact{' '}
              <Link href="mailto:payments@thesourcinggroup.com">
                payments@thesourcinggroup.com
              </Link>
            </Typography>
          </div>
        </div>
      </form>

      <PaymentConfirmationModal
        isOpen={isPaymentConfirmationOpen}
        handleCancel={() => setIsPaymentConfirmation(false)}
        handleConfirm={handlePaymentConfirmationConfirm}
      />
    </>
  );
};

export default InvoicePayment;

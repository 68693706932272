import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from '@mui/material';
import React, { useState } from 'react';
import Dropdown from '../../components/Dropdown/Dropdown';
import { inputsText } from './CreditCardCapture';

interface ICreditCardCaptureSection {
  inputs: inputsText[];
  handleInput: (key: string, value: string) => void;
  state: any;
  register: any;
  hasCheckBox?: boolean;
  checkSameInfo?: boolean;
  setCheckSameInfo?: any;
  options?: any;
  customerTypeOptions?: any;
  dropdownValue?: string;
  resetDropdown?: boolean;
}

const CreditCardCaptureSection = (props: ICreditCardCaptureSection) => {
  const {
    inputs,
    handleInput,
    state,
    hasCheckBox,
    checkSameInfo,
    setCheckSameInfo,
    options,
    customerTypeOptions,
    register,
    dropdownValue,
    resetDropdown,
  } = props;

  const [, setInputValues] = useState<{ [key: string]: string }>({});

  const handleInputChange = (key: string, value: string) => {
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [key]: value,
    }));
    handleInput(key, value);
  };

  return (
    <>
      {hasCheckBox && (
        <FormGroup style={{ display: 'flex', alignItems: 'center' }}>
          <FormControlLabel
            className="checkbox-same-info"
            control={
              <Checkbox
                key="checkbox"
                sx={{
                  color: `rgba(0, 0, 0, 0.54)`,
                }}
                checked={checkSameInfo}
                onClick={() => {
                  setCheckSameInfo(!checkSameInfo);
                }}
              />
            }
            label="Same as Billing information"
          />
        </FormGroup>
      )}
      <div className="section-container">
        {inputs.map((item: inputsText) => (
          <div key={item.id}>
            {item.type === 'dropdown' ? (
              <Dropdown
                resetDropdown={resetDropdown}
                dropdownValue={dropdownValue ? dropdownValue : state[item.id]}
                register={register}
                name={item.id}
                customDropdownStyles={{
                  width: '100%',
                  border: 'none',
                  marginRight: '19px',
                  marginTop: '5px',
                }}
                customOptionsStyles={{
                  fontFamily: `proxima-nova, sans-serif`,
                }}
                inputLabel={item.label}
                onChange={(value: any) => {
                  handleInputChange(item.id, value);
                }}
                options={
                  item.id === 'customerType' ? customerTypeOptions : options
                }
                error={item.error}
                helperText={item.helperText}
              />
            ) : (
              <TextField
                type={item.type === 'numeric' ? 'tel' : 'text'}
                {...register(item.id)}
                key={item.id}
                label={item.label}
                placeholder={item.label}
                className="credit-card-capture-input"
                variant="outlined"
                name={item.id}
                value={state[item.id.toString()] || ''}
                onInput={(e) =>
                  handleInputChange(
                    item.id,
                    (e.target as HTMLInputElement).value,
                  )
                }
                InputLabelProps={{
                  shrink: true,
                  style: {
                    fontFamily: 'proxima-nova, sans-serif !important',
                  },
                }}
                error={item.error}
                helperText={item.helperText}
                InputProps={{
                  classes: {
                    input: 'placeholder-style',
                  },
                  inputProps: {
                    pattern: item.type === 'numeric' ? '[0-9]*' : undefined,
                  },
                  style: {
                    fontFamily: `proxima-nova, sans-serif !important`,
                  },
                }}
                onKeyPress={(event) => {
                  const key = event.key;
                  if (
                    !(item.type === 'numeric') &&
                    !/^[0-9a-zA-Z\s+@.]+$/.test(key)
                  ) {
                    event.preventDefault();
                  } else if (item.type === 'numeric' && !/^\d+$/.test(key)) {
                    event.preventDefault();
                  }
                }}
              />
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default CreditCardCaptureSection;

import CloseIcon from '@mui/icons-material/Close';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { FC } from 'react';

export type PaymentConfirmationModalProps = {
  isOpen: boolean;
  handleCancel: () => void;
  handleConfirm: () => void;
};

const PaymentConfirmationModal: FC<PaymentConfirmationModalProps> = (props) => {
  const { isOpen, handleCancel, handleConfirm } = props;

  return (
    <Dialog
      open={isOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box display="flex" justifyContent="end">
        <IconButton
          aria-label="close"
          onClick={handleCancel}
          sx={{
            margin: '0.5rem 0.5rem 0 0',
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Box paddingBottom="1.25rem" display="flex" justifyContent="center">
        <Box
          width="6.25rem"
          height="6.25rem"
          borderRadius="50%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ backgroundColor: '#0a66c2' }}
        >
          <QuestionMarkIcon htmlColor="white" sx={{ fontSize: '3rem' }} />
        </Box>
      </Box>
      <DialogTitle
        id="alert-dialog-title"
        textAlign="center"
        fontFamily="proxima-nova"
        fontSize="1.5rem"
        padding="0 2.5rem"
      >
        Payment Confirmation
      </DialogTitle>
      <DialogContent sx={{ padding: '0 2.5rem 2.25rem' }}>
        <DialogContentText
          id="alert-dialog-description"
          textAlign="center"
          fontFamily="proxima-nova"
          fontSize="1rem"
        >
          Do you want to proceed with the payment?
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ padding: '0 2.5rem 3rem' }}>
        <Button
          size="large"
          className="btn"
          sx={{
            border: 'solid 2px #fc671a',
            lineHeight: '1.5rem',
            backgroundColor: 'white',
            color: '#080f12',
          }}
          onClick={handleCancel}
        >
          No
        </Button>
        <Button
          size="large"
          className="btn"
          sx={{
            backgroundColor: '#ff5100 !important',
            color: 'white',
          }}
          onClick={handleConfirm}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PaymentConfirmationModal;

import { AccessTokenContext, UserGroups } from 'App';
import NotFound from 'components/NotFound/NotFound';
import { useContext } from 'react';

interface ProtectedRouteProps {
  children: JSX.Element;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const { userGroup } = useContext(AccessTokenContext);

  if (!userGroup || !userGroup.includes(UserGroups.Admin)) {
    return <NotFound />;
  }
  return children;
};

export default ProtectedRoute;

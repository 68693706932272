import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Link } from 'react-router-dom';

const TransactionTableTabs = ({ value }: { value: string }) => {
  return (
    <Tabs value={value}>
      <Tab
        label="Customer Profiles"
        value="customer-profile"
        to="/customer-profile/transactions"
        component={Link}
      />
      <Tab
        label="Invoices"
        value="invoices"
        to="/invoice/transactions"
        component={Link}
      />
    </Tabs>
  );
};

export default TransactionTableTabs;

import Logo from '../../assets/TSG-Logo.png';

const NotFound = () => {
  return (
    <div className="wrapper">
      <img src={Logo} alt="TSG-Logo" className="logo-image" />
      <h1>404 - Page Not Found</h1>
      <p>Sorry, the page you are looking for could not be found.</p>
    </div>
  );
};

export default NotFound;

import { axiosGet } from 'helpers/axios-helpers';
import { CustomerProfileTransaction } from './types';

interface CustomerProfileTransactions {
  transactions: CustomerProfileTransaction[];
  count: number;
}

export const getCustomerProfileTransactions = async (
  access_token: string | undefined,
  page: number,
  perPage: number,
  sort?: string | null,
  order?: string,
  filter?: string,
) => {
  const response = await axiosGet<CustomerProfileTransactions>({
    route: `customer-profile/transactions?page=${
      page
    }&per_page=${perPage}${sort ? `&sort=${sort}&order=${order}` : ''}${
      filter ? `&filter=${filter}` : ''
    }`,
    accessToken: access_token,
  });
  return response.data;
};

import axios from 'axios';

type AxiosPost<T extends object> = {
  data: T;
  accessToken: string;
  route: string;
};

type AxiosGet = {
  accessToken?: string;
  route: string;
};

export function axiosPost<T extends object, R>(args: AxiosPost<T>) {
  const { route, accessToken, data } = args;

  return axios.post<R>(`${process.env.REACT_APP_PUBLIC_URL}/${route}`, data, {
    headers: {
      Authorization: accessToken,
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    },
  });
}

export function axiosGet<R>(args: AxiosGet) {
  const { route, accessToken } = args;

  return axios.get<R>(`${process.env.REACT_APP_PUBLIC_URL}/${route}`, {
    headers: {
      Authorization: accessToken || '',
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    },
  });
}

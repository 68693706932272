import type * as React from 'react';
import { ToastContainer } from 'react-toastify';
const ToastrComponent: React.FC<any> = () => {
  return (
    <section className="toastr-container">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </section>
  );
};

export default ToastrComponent;

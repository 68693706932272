import { Modal } from '@mui/material';
import React from 'react';

interface IBasicModal {
  open: boolean;
  children: JSX.Element;
}

export const BasicModal = (props: IBasicModal) => {
  const { open, children } = props;
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {children}
    </Modal>
  );
};

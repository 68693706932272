import './style/index.scss';
import '@aws-amplify/ui-react/styles.css';

import {
  Authenticator,
  Image,
  ThemeProvider,
  translations,
  useTheme,
  View,
} from '@aws-amplify/ui-react';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Amplify, Auth, I18n } from 'aws-amplify';
import jwt_decode from 'jwt-decode';
import InvoicePayment from 'pages/InvoicePayment/InvoicePayment';
import { SuccessfulCreditCardCharge } from 'pages/SuccessfulCreditCardCharge/SuccessfulCreditCardCharge';
import { createContext } from 'react';
import React from 'react';
import { AdobeFonts } from 'react-adobe-fonts';
import { Route, Routes } from 'react-router-dom';
import routes from 'routes/routes';
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';
import logo from './assets/TSG-Logo-big.png';
import awsExports from './aws-exports';
import ToastrComponent from './components/Toastr/ToastrComponent';
import CreditCardCapture from './pages/CreditCardCapture/CreditCardCapture';
import { SuccessfulAuthorization } from './pages/SuccessfulAuthorization/SuccessfulAuthorization';
import CustomerProfileTransactions from 'pages/CreditCardCapture/CustomerProfileTransactions';
import { showErrorToast } from 'helpers/showErrorToast';
import ProtectedRoute from 'components/ProtectedRoute/ProtectedRoute';
import NotFound from 'components/NotFound/NotFound';
import InvoiceTransactions from 'pages/InvoicePayment/InvoiceTransactions';

I18n.putVocabularies(translations);
I18n.setLanguage('en');

I18n.putVocabularies({
  en: {
    'We Texted You': 'We Emailed You',
    'Your code is on the way. To log in, enter the code we texted to':
      'Your code is on the way. To log in, enter the code we emailed to',
  },
});

Amplify.configure(awsExports);

interface IJwtObject {
  exp: number;
}
export interface IContext {
  name: string | undefined;
  email: string | undefined;
  userGroup: [string] | undefined;
  accessToken: string | undefined;
}

export enum UserGroups {
  Admin = 'admins',
}

export const AccessTokenContext = createContext<IContext>({
  name: '',
  email: '',
  userGroup: [''],
  accessToken: '',
});

const headerComponent = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image alt="TSG Logos" src={logo}></Image>
      </View>
    );
  },
};

const muiTheme = createTheme({
  palette: {
    primary: {
      main: '#ff5100',
    },
    secondary: {
      main: '#202945',
    },
  },
  typography: {
    fontFamily: `proxima-nova, sans-serif !important`,
  },
});

const theme = {
  name: 'TSG',
  typography: {
    fontFamily: `proxima-nova, sans-serif !important`,
  },
  tokens: {
    colors: {
      brand: {
        primary: {
          '10': '#f2f4f7',
          '80': '#ff5100',
          '90': '#e64a01',
          '100': '#202945',
        },
      },
    },
  },
};

export default function App() {
  React.useEffect(() => {
    checkJWT();
  }, []);

  const checkJWT = async () => {
    try {
      const user: CognitoUser = await Auth.currentAuthenticatedUser();
      const userSession = user?.getSignInUserSession();
      const jwtToken = userSession?.getIdToken().getJwtToken();
      const now = Math.floor(Date.now() / 1000);

      if (jwtToken) {
        const expiration: IJwtObject = jwt_decode(jwtToken);
        if (expiration.exp <= now) {
          showErrorToast('Your session has expired, please log in again.');
          Auth.signOut();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <AdobeFonts kitId="fru2htc" />
      <ThemeProvider theme={theme}>
        <MuiThemeProvider theme={muiTheme}>
          <ToastrComponent />
          <Authenticator components={headerComponent}>
            {({ user }) => (
              <AccessTokenContext.Provider
                value={{
                  name: user?.attributes?.name,
                  email: user?.attributes?.email,
                  userGroup: user
                    ?.getSignInUserSession()
                    ?.getAccessToken()
                    .decodePayload()?.['cognito:groups'],
                  accessToken: user
                    ?.getSignInUserSession()
                    ?.getAccessToken()
                    .getJwtToken(),
                }}
              >
                <div className="app">
                  <Routes>
                    <Route
                      path={routes.invoiceTransactions}
                      element={
                        <ProtectedRoute>
                          <InvoiceTransactions />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path={routes.customerProfileTransactions}
                      element={
                        <ProtectedRoute>
                          <CustomerProfileTransactions />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path={routes.creditCardCapture}
                      element={<CreditCardCapture />}
                    />
                    <Route
                      path={routes.successfulAuthorization}
                      element={<SuccessfulAuthorization />}
                    />
                    <Route
                      path={routes.successfulCCCharge}
                      element={<SuccessfulCreditCardCharge />}
                    />
                    <Route
                      path={routes.exemptedPayment}
                      element={<InvoicePayment />}
                    />
                    <Route path={routes.root} element={<InvoicePayment />} />
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </div>
              </AccessTokenContext.Provider>
            )}
          </Authenticator>
        </MuiThemeProvider>
      </ThemeProvider>
    </>
  );
}

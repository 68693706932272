import React, { useMemo, useState, useContext } from 'react';
import { Auth } from 'aws-amplify';
import CreditCardCaptureSection from './CreditCardCaptureSection';
import { Button, TextField } from '@mui/material';
import CollapsibleCard from '../../components/CollapsableCard/CollapsableCard';
import Cards from 'react-credit-cards-2';
import 'react-credit-cards-2/dist/lib/styles.scss';
import { useForm } from 'react-hook-form';
import countryList from 'react-select-country-list';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Header from '../../components/Header/Header';
import { IAuthorizeCC } from './helpers/AuthorizeCreditCard';
import { AccessTokenContext } from '../../App';
import routes from '../../routes/routes';
import { useNavigate } from 'react-router-dom';
import { Spinner } from '../../components/Spinner/Spinner';
import { BasicModal } from '../../components/BasicModal/BasicModal';
import { createCustomerProfile } from './helpers/CreateCustomerProfile';
import { showErrorToast } from 'helpers/showErrorToast';

const schema = yup.object({
  customerType: yup.string().required('Customer Type is required'),

  customerId: yup
    .string()
    .max(20, 'Customer Id must be 20 characters or less')
    .required('Customer ID is required'),
  cardNumber: yup
    .string()
    .max(16, 'The card must be 16 characters or less')
    .required('Card Number is required')
    .matches(/^[0-9]+$/, 'Must be only digits'),
  cvv: yup.string().required('CVV is required'),
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  address: yup.string().required('Address is required'),
  city: yup.string().required('City is required'),
  state: yup.string().required('State is required'),
  zipCode: yup.string().required('Zip code is required'),
  country: yup.string().required('Country is required'),
  expirationDate: yup
    .string()
    .required('Card Expiration Date is required.')
    .test('custom-validation', 'Invalid Expiration date', (value) => {
      const month = Number(value.split('/')[0]);
      const year = value.split('/')[1];
      const today = new Date();
      const correctMonth = month < 13;
      const sameYear = today.getFullYear() === Number(`20${year}`);
      const expiresOnAnotherYear = today.getFullYear() < Number(`20${year}`);
      const expiresOnAnotherMonth = today.getMonth() + 1 < month;

      if (correctMonth && expiresOnAnotherYear) {
        return true;
      } else if (sameYear) {
        if (expiresOnAnotherMonth && correctMonth) {
          return true;
        }
      }
      return false;
    }),
  email: yup
    .string()
    .email('Invalid email format.')
    .required('Email is required.'),
});

interface IPaymentAuthorizationInfo {
  cardNumber: string;
  userName: string;
  expirationDate: string;
  cvv: string;
  focus: any;
}

export interface inputsText {
  label: string;
  id: string;
  type: string;
  helperText?: string;
  error?: any;
}
interface ICustomerProfile {
  customerId: string;
  email: string;
}

interface ICustomerBillingInformation {
  firstName: string;
  lastName: string;
  company: string;
  address: string;
  customerType: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  phone: string;
  fax: string;
}

interface IShippingInformation {
  shippingFirstName: string;
  shippingLastName: string;
  shippingCompany: string;
  shippingAddress: string;
  shippingCity: string;
  shippingState: string;
  shippingZipCode: string;
  shippingCountry: string;
  shippingPhone: string;
  shippingFax: string;
}

type IFormAccordions = Record<
  'customerProfile' | 'payment' | 'billing' | 'shipping',
  boolean
>;

const CreditCardCapture = (): JSX.Element => {
  const {
    handleSubmit,
    register,
    reset,
    setValue,
    getValues,

    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      cardNumber: '',
      userName: '',
      expirationDate: '',
      cvv: '',
      customerId: '',
      customerType: 'business',
      firstName: '',
      lastName: '',
      company: '',
      address: '',
      city: '',
      state: '',
      zipCode: '',
      country: '',
      phone: '',
      fax: '',
      email: '',
      shippingFirstName: '',
      shippingLastName: '',
      shippingCompany: '',
      shippingAddress: '',
      shippingCity: '',
      shippingState: '',
      shippingZipCode: '',
      shippingCountry: '',
      shippingPhone: '',
      shippingFax: '',
    },
  });

  const { accessToken } = useContext(AccessTokenContext);
  const navigate = useNavigate();
  const [customerProfileRecordId, setCustomerProfileRecordId] =
    useState<number>();
  const onSubmit = async (data: any) => {
    try {
      setOpenBasicModal(true);
      let body: IAuthorizeCC = getValues();
      if (data !== undefined) {
        body = {
          ...data,
          shippingFirstName: data.shippingFirstName,
          shippingLastName: data.shippingLastName,
          shippingCompany: data.shippingCompany,
          shippingAddress: data.shippingAddress,
          shippingCity: data.shippingCity,
          shippingState: data.shippingState,
          shippingZipCode: data.shippingZipCode,
          shippingCountry: data.shippingCountry,
          shippingPhone: data.shippingPhone,
          shippingFax: data.shippingFax,
          ...(customerProfileRecordId ? { customerProfileRecordId } : {}),
        };
      }
      const createCustomerProfileResult = await createCustomerProfile(
        body,
        accessToken,
      );
      if (createCustomerProfileResult.customerProfileRecordId) {
        setCustomerProfileRecordId(
          createCustomerProfileResult.customerProfileRecordId,
        );
        showErrorToast(createCustomerProfileResult.error);
      } else if (createCustomerProfileResult.statusCode === 200) {
        navigate(routes.successfulAuthorization);
      } else {
        showErrorToast(createCustomerProfileResult.error);
      }
    } catch (error: any) {
      if (error.response) {
        if (error.response.status === 403) {
          showErrorToast('Your session has expired, please log in again.');
          Auth.signOut();
          return;
        }
        showErrorToast(error.response.data.error);
      } else {
        showErrorToast(
          'The system is currently unavailable. Please try again later.',
        );
      }
    } finally {
      setOpenBasicModal(false);
    }
  };

  const emptyPaymentAuthorization = {
    cardNumber: '',
    userName: '',
    expirationDate: '',
    cvv: '',
    focus: '',
  };
  const emptyCustomerProfile = {
    customerId: '',
    email: '',
  };

  const emptyCustomerBilling = {
    firstName: '',
    lastName: '',
    company: '',
    customerType: 'business',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
    phone: '',
    fax: '',
  };
  const emptyShipping = {
    shippingFirstName: '',
    shippingLastName: '',
    shippingCompany: '',
    shippingAddress: '',
    shippingCity: '',
    shippingState: '',
    shippingZipCode: '',
    shippingCountry: '',
    shippingPhone: '',
    shippingFax: '',
  };

  const [openBasicModal, setOpenBasicModal] = useState<boolean>(false);
  const [checkSameInfo, setCheckSameInfo] = useState<boolean>(false);
  const [copyDropdown, setCopyDropdown] = useState<string>('');
  const [resetDropdown, setResetDropdown] = useState<boolean>(false);
  const [customerProfile, setCustomerProfile] = useState<ICustomerProfile>({
    ...emptyCustomerProfile,
  });
  const [paymentAuthorizationInfo, setPaymentAuthorizationInfo] =
    useState<IPaymentAuthorizationInfo>({ ...emptyPaymentAuthorization });
  const [customerBillingInformation, setCustomerBillingInformation] =
    useState<ICustomerBillingInformation>({ ...emptyCustomerBilling });
  const [shippingInformation, setShippingInformation] =
    useState<IShippingInformation>({ ...emptyShipping });
  const [formAccordions, setFormAccordions] = useState<IFormAccordions>({
    billing: true,
    customerProfile: true,
    payment: true,
    shipping: true,
  });

  const options = useMemo(() => {
    const countries = countryList().getData();
    const unitedStates = countries.find(
      (item: { label: string }) => item.label === 'United States',
    );
    const filteredCountries = countries.filter(
      (item: { label: string }) => item.label !== 'United States',
    );
    const updatedOptions = [
      {
        value: unitedStates?.value,
        label: unitedStates?.label,
      },
      ...filteredCountries.map((item) => ({
        value: item.value,
        label: item.label,
      })),
    ];
    return updatedOptions;
  }, []);

  const customerTypesOptions = [
    { value: 'individual', label: 'Individual' },
    { value: 'business', label: 'Business' },
  ];

  const customerProfileInputs: inputsText[] = [
    {
      label: 'Customer ID*',
      id: 'customerId',
      type: 'text',
      error: Boolean(errors.customerId),
      helperText: errors.customerId?.message,
    },
    {
      label: 'Email*',
      id: 'email',
      type: 'text',
      error: Boolean(errors.email),
      helperText: errors.email?.message,
    },
  ];

  const customerBillingInformationInputs: inputsText[] = [
    {
      label: 'First Name*',
      id: 'firstName',
      type: 'text',
      error: Boolean(errors.firstName),
      helperText: errors.firstName?.message,
    },
    {
      label: 'Last Name*',
      id: 'lastName',
      type: 'text',
      error: Boolean(errors.lastName),
      helperText: errors.lastName?.message,
    },
    {
      label: 'Customer Type',
      id: 'customerType',
      type: 'dropdown',
      error: Boolean(errors.customerType),
      helperText: errors.customerType?.message,
    },
    {
      label: 'Company',
      id: 'company',
      type: 'text',
    },
    {
      label: 'Address*',
      id: 'address',
      type: 'text',
      error: Boolean(errors.address),
      helperText: errors.address?.message,
    },
    {
      label: 'City*',
      id: 'city',
      type: 'text',
      error: Boolean(errors.city),
      helperText: errors.city?.message,
    },
    {
      label: 'State/Province*',
      id: 'state',
      type: 'text',
      error: Boolean(errors.state),
      helperText: errors.state?.message,
    },
    {
      label: 'Zip Code*',
      id: 'zipCode',
      type: 'text',
      error: Boolean(errors.zipCode),
      helperText: errors.zipCode?.message,
    },
    {
      label: 'Country',
      id: 'country',
      type: 'dropdown',
      error: Boolean(errors.country),
      helperText: errors.country?.message,
    },
    {
      label: 'Phone',
      id: 'phone',
      type: 'numeric',
    },
    {
      label: 'Fax',
      id: 'fax',
      type: 'numeric',
    },
  ];

  const shippingInformationInputs: inputsText[] = [
    { label: 'First Name', id: 'shippingFirstName', type: 'text' },
    { label: 'Last Name', id: 'shippingLastName', type: 'text' },
    { label: 'Company', id: 'shippingCompany', type: 'text' },
    { label: 'Address', id: 'shippingAddress', type: 'text' },
    { label: 'City', id: 'shippingCity', type: 'text' },
    { label: 'State/Province', id: 'shippingState', type: 'text' },
    { label: 'Zip Code', id: 'shippingZipCode', type: 'text' },
    { label: 'Country', id: 'shippingCountry', type: 'dropdown' },
    { label: 'Phone', id: 'shippingPhone', type: 'numeric' },
    { label: 'Fax', id: 'shippingFax', type: 'numeric' },
  ];

  React.useEffect(() => {
    handleCheckSameInformation();
  }, [checkSameInfo]);

  React.useEffect(() => {
    return () => {
      setResetDropdown(false);
    };
  }, [resetDropdown]);

  const handleInputPaymentAuthorization = (
    key: string,
    value: string,
  ): void => {
    setPaymentAuthorizationInfo({
      ...paymentAuthorizationInfo,
      [key]: value,
    });
  };

  const handleInputFocus = (evt: any) => {
    setPaymentAuthorizationInfo((prev) => ({
      ...prev,
      focus: evt.target.name,
    }));
  };

  const handleInputCustomerBillingInfo = (key: string, value: string): void => {
    setCustomerBillingInformation({
      ...customerBillingInformation,
      [key]: value,
    });
  };
  const handleInputCustomerProfile = (key: string, value: string): void => {
    setCustomerProfile({
      ...customerProfile,
      [key]: value,
    });
  };

  const handleInputShippingInfo = (key: string, value: string): void => {
    setShippingInformation({
      ...shippingInformation,
      [key]: value,
    });
  };

  const formReset = () => {
    setPaymentAuthorizationInfo({ ...emptyPaymentAuthorization });
    setCustomerBillingInformation({ ...emptyCustomerBilling });
    setShippingInformation({ ...emptyShipping });
    setCustomerProfile({ ...emptyCustomerProfile });
    reset();
    setResetDropdown(true);
    setCheckSameInfo(false);
    setCopyDropdown('');
  };

  const handleCheckSameInformation = () => {
    setValue('shippingFirstName', checkSameInfo ? getValues('firstName') : '');
    setValue('shippingLastName', checkSameInfo ? getValues('lastName') : '');
    setValue('shippingCompany', checkSameInfo ? getValues('company') : '');
    setValue('shippingAddress', checkSameInfo ? getValues('address') : '');
    setValue('shippingCity', checkSameInfo ? getValues('city') : '');
    setValue('shippingState', checkSameInfo ? getValues('state') : '');
    setValue('shippingZipCode', checkSameInfo ? getValues('zipCode') : '');
    setValue('shippingCountry', checkSameInfo ? getValues('country') : '');
    setValue('shippingPhone', checkSameInfo ? getValues('phone') : '');
    setValue('shippingFax', checkSameInfo ? getValues('fax') : '');
    if (checkSameInfo) {
      setShippingInformation({
        shippingFirstName: customerBillingInformation.firstName,
        shippingLastName: customerBillingInformation.lastName,
        shippingCompany: customerBillingInformation.company,
        shippingAddress: customerBillingInformation.address,
        shippingCity: customerBillingInformation.city,
        shippingState: customerBillingInformation.state,
        shippingZipCode: customerBillingInformation.zipCode,
        shippingCountry: customerBillingInformation.country,
        shippingPhone: customerBillingInformation.phone,
        shippingFax: customerBillingInformation.fax,
      });
    } else {
      setShippingInformation({
        ...emptyShipping,
      });
    }
  };

  return (
    <>
      <Header />

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="creditCard-main-container">
          <BasicModal open={openBasicModal}>
            <div className="modal-div">
              <Spinner />
              <p className="spinner-title">Loading</p>
              <span className="spinner-description"> Please hold</span>
            </div>
          </BasicModal>
          <CollapsibleCard
            title="Customer Profile Information"
            isOpen={formAccordions.customerProfile}
            setIsOpen={(isOpen) =>
              setFormAccordions((prevValues) => ({
                ...prevValues,
                customerProfile: isOpen,
              }))
            }
          >
            <CreditCardCaptureSection
              options={options}
              register={register}
              inputs={customerProfileInputs}
              resetDropdown={resetDropdown}
              handleInput={handleInputCustomerProfile}
              state={customerProfile}
            />
          </CollapsibleCard>
          <CollapsibleCard
            title="Payment Information"
            isOpen={formAccordions.payment}
            setIsOpen={(isOpen) =>
              setFormAccordions((prevValues) => ({
                ...prevValues,
                payment: isOpen,
              }))
            }
          >
            <div className="creditCard-container">
              <Cards
                number={paymentAuthorizationInfo.cardNumber}
                expiry={paymentAuthorizationInfo.expirationDate}
                cvc={paymentAuthorizationInfo.cvv}
                name={paymentAuthorizationInfo.userName}
                focused={paymentAuthorizationInfo.focus}
              />
              <div>
                <TextField
                  {...register('cardNumber')}
                  label="Card Number*"
                  placeholder="0000 0000 0000 0000"
                  className="credit-card-capture-input"
                  variant="outlined"
                  name="cardNumber"
                  value={paymentAuthorizationInfo.cardNumber}
                  onChange={(e) => {
                    handleInputPaymentAuthorization(
                      'cardNumber',
                      e.target.value,
                    );
                  }}
                  onFocus={handleInputFocus}
                  InputLabelProps={{
                    shrink: true,
                    style: {
                      fontFamily: 'proxima-nova, sans-serif !important',
                    },
                  }}
                  InputProps={{
                    classes: {
                      input: 'placeholder-style',
                    },
                    style: {
                      fontFamily: `proxima-nova, sans-serif !important`,
                    },
                  }}
                  type="number"
                  onKeyDown={(event) => {
                    const key = event.key;
                    const isNumericKey = /^[0-9]$/.test(key);
                    const isDeleteBackspaceTabKey =
                      /^(Delete|Backspace|Tab)$/.test(key);
                    if (!isNumericKey && !isDeleteBackspaceTabKey) {
                      event.preventDefault();
                    }
                  }}
                  error={Boolean(errors.cardNumber)}
                  helperText={errors.cardNumber?.message}
                />

                <TextField
                  {...register('userName')}
                  label="Name"
                  placeholder="Name"
                  className="credit-card-capture-input"
                  variant="outlined"
                  name="userName"
                  value={paymentAuthorizationInfo.userName}
                  onChange={(e) => {
                    handleInputPaymentAuthorization('userName', e.target.value);
                  }}
                  onFocus={handleInputFocus}
                  InputLabelProps={{
                    shrink: true,
                    style: {
                      fontFamily: 'proxima-nova, sans-serif !important',
                    },
                  }}
                  InputProps={{
                    classes: {
                      input: 'placeholder-style',
                    },
                    style: {
                      fontFamily: 'proxima-nova, sans-serif !important',
                    },
                  }}
                />
                <div style={{ display: 'flex' }}>
                  <TextField
                    {...register('expirationDate')}
                    label="Expiration Date*"
                    placeholder="MM / YY"
                    className="credit-card-capture-expiration"
                    variant="outlined"
                    name="expirationDate"
                    value={paymentAuthorizationInfo.expirationDate}
                    onFocus={handleInputFocus}
                    style={{ paddingRight: '48px !important' }}
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        fontFamily: 'proxima-nova, sans-serif !important',
                      },
                    }}
                    InputProps={{
                      classes: {
                        input: 'placeholder-style',
                      },
                      style: {
                        fontFamily: 'proxima-nova, sans-serif !important',
                      },
                      inputProps: {
                        maxLength: 5,
                      },
                    }}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      const sanitizedValue = inputValue.replace(/[^\d]/g, '');
                      let formattedValue = '';
                      if (sanitizedValue.length > 0) {
                        formattedValue = sanitizedValue.slice(0, 2);
                        if (sanitizedValue.length > 2) {
                          formattedValue += '/' + sanitizedValue.slice(2);
                        }
                      }
                      handleInputPaymentAuthorization(
                        'expirationDate',
                        formattedValue,
                      );
                    }}
                    error={Boolean(errors.expirationDate)}
                    helperText={errors.expirationDate?.message}
                  />

                  <TextField
                    {...register('cvv')}
                    label="CVV*"
                    placeholder="000"
                    className="credit-card-capture-input"
                    value={paymentAuthorizationInfo.cvv}
                    variant="outlined"
                    onFocus={handleInputFocus}
                    name="cvv"
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      const fourDigits = inputValue
                        .replace(/\D/g, '')
                        .slice(0, 4);
                      handleInputPaymentAuthorization('cvv', fourDigits);
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        fontFamily: 'proxima-nova, sans-serif !important',
                      },
                    }}
                    InputProps={{
                      classes: {
                        input: 'placeholder-style',
                      },
                      style: {
                        fontFamily: 'proxima-nova, sans-serif !important',
                      },
                    }}
                    error={Boolean(errors.cvv)}
                    helperText={errors.cvv?.message}
                  />
                </div>
              </div>
            </div>
          </CollapsibleCard>

          <CollapsibleCard
            title="Customer Billing Information"
            isOpen={formAccordions.billing}
            setIsOpen={(isOpen) =>
              setFormAccordions((prevValues) => ({
                ...prevValues,
                billing: isOpen,
              }))
            }
          >
            <CreditCardCaptureSection
              options={options}
              customerTypeOptions={customerTypesOptions}
              register={register}
              inputs={customerBillingInformationInputs}
              resetDropdown={resetDropdown}
              handleInput={handleInputCustomerBillingInfo}
              state={customerBillingInformation}
            />
          </CollapsibleCard>

          <CollapsibleCard
            title="Shipping Information"
            isOpen={formAccordions.shipping}
            setIsOpen={(isOpen) =>
              setFormAccordions((prevValues) => ({
                ...prevValues,
                shipping: isOpen,
              }))
            }
          >
            <CreditCardCaptureSection
              options={options}
              dropdownValue={copyDropdown}
              register={register}
              inputs={shippingInformationInputs}
              handleInput={handleInputShippingInfo}
              state={shippingInformation}
              hasCheckBox
              resetDropdown={resetDropdown}
              checkSameInfo={checkSameInfo}
              setCheckSameInfo={setCheckSameInfo}
            />
          </CollapsibleCard>
          <div className="credit-card-capture-button-container">
            <Button
              className="btn cancel-action-btn"
              style={{ marginRight: 32, backgroundColor: 'white' }}
              onClick={formReset}
            >
              Reset
            </Button>
            <Button
              type="submit"
              className="btn submit-action-btn"
              variant="contained"
            >
              Submit
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

export default CreditCardCapture;

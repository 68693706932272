import { Button } from '@mui/material';
import React from 'react';
import Lottie from 'react-lottie';
import { useNavigate } from 'react-router-dom';
import routes from 'routes/routes';

import animationData from '../../assets/success.json';
import Header from '../../components/Header/Header';

export const SuccessfulAuthorization = () => {
  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const navigate = useNavigate();

  return (
    <>
      <Header />
      <div className="main-container">
        <div className="subHeader-container">
          <div className="circle-container">
            <Lottie options={lottieOptions} />
          </div>
        </div>
        <div className="container">
          <p className="thank">Thank you!</p>
          <span className="thank-description">
            The customer profile has been updated successfully.
          </span>
          <Button
            className="btn submit-action-btn"
            variant="contained"
            onClick={() => {
              navigate(routes.creditCardCapture);
            }}
          >
            Enter another card
          </Button>
        </div>
      </div>
    </>
  );
};

import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import DataTable, { SortOrder, TableColumn } from 'react-data-table-component';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { getCustomerProfileTransactions } from './helpers/GetTransactions';
import { AccessTokenContext } from 'App';
import Header from 'components/Header/Header';
import { useDebounce } from 'helpers/useDebounce';
import { showErrorToast } from 'helpers/showErrorToast';
import { customerProfileTransactionColumns } from 'helpers/transactionColumns';
import { CustomerProfileTransaction } from './helpers/types';
import TransactionTableTabs from 'components/Tabs/TransactionTableTabs';

const ExpandedComponent = ({
  data: {
    authorizeTransactionResponse,
    paytraceTransactionResponse,
    authorizeErrorMessage,
    paytraceErrorMessage,
    billingAddress: { address, city, state, zipCode, country },
  },
}: {
  data: CustomerProfileTransaction;
}) => {
  const jsonValues = [
    { name: 'Authorize.net Response', value: authorizeTransactionResponse },
    { name: 'Paytrace Response', value: paytraceTransactionResponse },
    { name: 'Authorize Error Message', value: authorizeErrorMessage },
    { name: 'Paytrace Error Message', value: paytraceErrorMessage },
  ];
  return (
    <div className="transactionCollapsiblePanel">
      {jsonValues.map(({ name, value }) =>
        value ? (
          <div className="transactionResponse">
            <small>{name}</small>
            <table className="transactionResponseTable">
              {Object.keys(value).map((row) => (
                <tr>
                  <td>{row}</td>
                  <td>
                    <pre>
                      {typeof value[row] === 'string'
                        ? `${value[row]}`
                        : JSON.stringify(value[row], null, 2)}
                    </pre>
                  </td>
                </tr>
              ))}
            </table>
          </div>
        ) : (
          <></>
        ),
      )}
      <div className="transactionResponse">
        <small>Address</small>
        <pre>
          {address}, {city}, {state} {zipCode} {country}
        </pre>
      </div>
    </div>
  );
};

const CustomerProfileTransactions = (): JSX.Element => {
  const { accessToken } = useContext(AccessTokenContext);
  const [data, setData] = useState<CustomerProfileTransaction[]>([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sort, setSort] = useState<string>();
  const [order, setOrder] = useState<string>();
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const firstRender = useRef(true);

  const fetchTransactions = async (page: number) => {
    try {
      setLoading(true);
      const { transactions, count } = await getCustomerProfileTransactions(
        accessToken,
        page,
        perPage,
        sort,
        order,
      );
      firstRender.current = false;
      setData(transactions);
      setTotalRows(count);
      setPage(page);
      setLoading(false);
    } catch (error) {
      showErrorToast(
        'There was an error getting the data. Please try again later.',
      );
    }
  };

  const handlePageChange = (page: number) => {
    fetchTransactions(page);
  };

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    try {
      setLoading(true);
      const { transactions, count } = await getCustomerProfileTransactions(
        accessToken,
        page,
        newPerPage,
        sort,
        order,
      );
      setData(transactions);
      setTotalRows(count);
      setPage(page);
      setPerPage(newPerPage);
      setLoading(false);
    } catch (error) {
      showErrorToast(
        'There was an error getting the data. Please try again later.',
      );
    }
  };

  const handleSort = async (
    column: TableColumn<CustomerProfileTransaction>,
    sortDirection: SortOrder,
  ) => {
    try {
      setLoading(true);
      const { transactions, count } = await getCustomerProfileTransactions(
        accessToken,
        page,
        perPage,
        column.sortField,
        sortDirection,
      );
      setData(transactions);
      setTotalRows(count);
      setSort(column.sortField);
      setOrder(sortDirection);
      setLoading(false);
    } catch (error) {
      showErrorToast(
        'There was an error getting the data. Please try again later.',
      );
    }
  };

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <TextField
        style={{ marginTop: '15px' }}
        onChange={(e) => setFilterText(e.target.value)}
        value={filterText}
        InputProps={{
          endAdornment: (
            <Button onClick={handleClear} className="clearButton">
              x
            </Button>
          ),
        }}
        className="filterInput"
        placeholder="Search"
      />
    );
  }, [filterText, resetPaginationToggle]);

  const onFilter = useCallback(async (filterText: string) => {
    try {
      setLoading(true);
      const { transactions, count } = await getCustomerProfileTransactions(
        accessToken,
        page,
        perPage,
        sort,
        order,
        filterText,
      );
      setData(transactions);
      setTotalRows(count);
      setLoading(false);
    } catch (error) {
      showErrorToast(
        'There was an error getting the data. Please try again later.',
      );
    }
  }, []);

  const debouncedOnFilter = useDebounce(onFilter, 700);

  useEffect(() => {
    if (firstRender.current) return;
    debouncedOnFilter(filterText);
  }, [filterText]);

  useEffect(() => {
    fetchTransactions(1);
  }, []);

  return (
    <>
      <Header />
      <TransactionTableTabs value="customer-profile" />
      <DataTable
        columns={customerProfileTransactionColumns}
        data={data}
        progressPending={loading}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        sortServer
        onSort={handleSort}
        expandableRows
        expandableRowsComponent={ExpandedComponent}
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
      />
    </>
  );
};

export default CustomerProfileTransactions;

import { Transaction } from 'types/Transaction';
import { dateFormat } from './dateFormat';
import { InvoiceTransaction } from 'pages/InvoicePayment/types';
import { checkmarkOrX } from './checkmarkOrX';
import { CustomerProfileTransaction } from 'pages/CreditCardCapture/helpers/types';

const createdAt = {
  name: 'Created date',
  selector: (row: Transaction) => row.createdAt,
  format: (row: Transaction) => dateFormat(row.createdAt),
  width: '160px',
  sortable: true,
  sortField: 'createdAt',
};
const customerId = {
  name: 'Customer ID',
  selector: (row: Transaction) => row.customerId,
  sortable: true,
  sortField: 'customerId',
};
const email = {
  name: 'Email',
  selector: (row: Transaction) => row.email,
  width: '300px',
  sortable: true,
  sortField: 'email',
};
const firstName = {
  name: 'First name',
  selector: (row: Transaction) => row.billingAddress.firstName,
  sortable: true,
  sortField: 'billingAddress.firstName',
};
const lastName = {
  name: 'Last name',
  selector: (row: Transaction) => row.billingAddress.lastName,
  sortable: true,
  sortField: 'billingAddress.lastName',
};
const ccLastFourDigits = {
  name: 'Last 4 digits',
  selector: (row: Transaction) => row.ccLastFourDigits,
  sortable: true,
  sortField: 'ccLastFourDigits',
};
const company = {
  name: 'Company',
  selector: (row: Transaction) => row.billingAddress.company,
  sortable: true,
  sortField: 'billingAddress.company',
};

export const customerProfileTransactionColumns = [
  createdAt,
  customerId,
  email,
  firstName,
  lastName,
  ccLastFourDigits,
  company,
  {
    name: 'Authorize successful result',
    selector: (row: CustomerProfileTransaction) => row.isAuthorizeCreated,
    cell: (row: CustomerProfileTransaction) =>
      checkmarkOrX(row.isAuthorizeCreated),
    sortable: true,
    sortField: 'isAuthorizeCreated',
  },
  {
    name: 'Paytrace successful result',
    selector: (row: CustomerProfileTransaction) => row.isPaytraceCreated,
    cell: (row: CustomerProfileTransaction) =>
      checkmarkOrX(row.isPaytraceCreated),
    sortable: true,
    sortField: 'isPaytraceCreated',
  },
];

export const invoiceTransactionColumns = [
  createdAt,
  customerId,
  {
    name: 'Invoice number',
    selector: (row: InvoiceTransaction) => row.invoiceNumber,
    sortable: true,
    sortField: 'invoiceNumber',
  },
  {
    name: 'Email',
    selector: (row: InvoiceTransaction) => row.userEmail,
    width: '300px',
    sortable: true,
    sortField: 'userEmail',
  },
  firstName,
  lastName,
  {
    name: 'Amount',
    selector: (row: InvoiceTransaction) => `$${row.amount}`,
    sortable: true,
    sortField: 'amount',
  },
  ccLastFourDigits,
  company,
  {
    name: 'Is exempted',
    selector: (row: InvoiceTransaction) => row.isExempted,
    sortable: true,
    sortField: 'isExempted',
    cell: (row: InvoiceTransaction) => checkmarkOrX(row.isExempted),
  },
  {
    name: 'Authorize successful result',
    selector: (row: InvoiceTransaction) => row.isPaymentCharged,
    cell: (row: InvoiceTransaction) => checkmarkOrX(row.isPaymentCharged),
    sortable: true,
    sortField: 'isPaymentCharged',
  },
];

const routes = {
  root: '/',
  creditCardCapture: '/customer-profile',
  successfulAuthorization: '/success',
  successfulCCCharge: '/success-credit-card-charge',
  exemptedPayment: '/exempted-payment',
  customerProfileTransactions: '/customer-profile/transactions',
  invoiceTransactions: 'invoice/transactions',
} as const;

export default routes;

import { Button } from '@mui/material';
import { FC } from 'react';
import Lottie from 'react-lottie';
import { useNavigate } from 'react-router-dom';

import animationData from '../../assets/success.json';
import Header from '../../components/Header/Header';

export const SuccessfulCreditCardCharge: FC = () => {
  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const navigate = useNavigate();

  return (
    <>
      <Header />
      <div className="main-container">
        <div className="subHeader-container">
          <div className="circle-container">
            <Lottie options={lottieOptions} />
          </div>
        </div>
        <div className="container">
          <p className="thank">Congratulations!</p>
          <span className="thank-description">
            Credit card was successfully charged.
          </span>
          <Button
            className="btn submit-action-btn"
            variant="contained"
            onClick={() => {
              navigate(-1);
            }}
          >
            Pay another invoice
          </Button>
        </div>
      </div>
    </>
  );
};
